import { logEvent } from 'utils/amplitude';

export const logViewOffer = (featureName: string): void =>
  logEvent('Purchase - View in-app purchase feature overview', { 'Feature Viewed': featureName });

export const logCloseOffer = (featureName: string): void =>
  logEvent('Purchase - Close in-app purchase feature overview', { 'Feature Viewed': featureName });

export const logClickOffer = (featureName: string): void =>
  logEvent('Purchase - Click call-to-action in in-app purchase experience', {
    'Feature Viewed': featureName,
    'Call-to-action': 'Activate trial',
  });

export const logViewConfirmation = (featureName: string): void =>
  logEvent('Purchase - View call-to-action confirmation', {
    'Feature Viewed': featureName,
    'Call-to-action': 'Activate trial',
  });

export const logCloseConfirmation = (featureName: string): void =>
  logEvent('Purchase - Close call-to-action confirmation', {
    'Feature Viewed': featureName,
    'Call-to-action': 'Activate trial',
  });

export const logOpenTrialDialog = (featureName: string, source: string): void =>
  logEvent('Purchase - Click link to open in-app purchase experience', {
    source,
    'Featured Viewed': featureName,
  });
