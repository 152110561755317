import React from 'react';
import { Button2, DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { formatDateDiff } from 'utils/date/date';
import { TrialViewProps } from '../TrialActivationDialog';

export const ExampleActivatedView: React.FC<TrialViewProps> = ({ onClose, currentStatus }) => {
  const expireTime = currentStatus?.expire_at ? new Date(currentStatus?.expire_at) : undefined;
  return (
    <Stack>
      <Flexbox justify="space-between" align="center">
        <HeaderText tag="h1">You are Activated</HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <Text2>
        Urna neque viverra justo nec ultrices. Nec dui nunc mattis enim. Aliquam ut porttitor leo a.
        Cursus turpis massa tincidunt dui ut ornare lectus. Ut etiam sit amet nisl purus in mollis
        nunc sed. Senectus et netus et malesuada fames ac. Arcu risus quis varius quam quisque id
        diam vel quam. Scelerisque fermentum dui faucibus in ornare. Tortor consequat id porta nibh
        venenatis cras. Aliquet nec ullamcorper sit amet risus nullam eget felis eget. Auctor eu
        augue ut lectus arcu bibendum at. Maecenas ultricies mi eget mauris pharetra et ultrices
        neque.
      </Text2>
      {expireTime ? <Text2 tag="strong">Expires: {formatDateDiff(expireTime)} </Text2> : null}
      <Button2 label="Close" onClick={onClose} />
    </Stack>
  );
};
