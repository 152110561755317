import get from 'lodash/get';
import { createSelector } from 'reselect';
import { mapArrayToObject } from 'utils/arrays';

import { EntitiesById, RootState } from 'StoreTypes';
import { PracticeUser, UserInviteStatus } from 'EntityTypes';
import { makeSelectWorkspaceFilteredEntites } from 'modules/workspace-filters';
import practiceUsersHelpers from './practiceUsersHelpers';

/**
 * Creates a sort function that sorts users matching the given default physician user ID
 * first, before other users.
 */
function makeDefaultPhysicianSortFn(defaultPhysicianUserId: number) {
  return (a: PracticeUser, b: PracticeUser): number => {
    if (a.id === defaultPhysicianUserId) return -1;
    if (b.id === defaultPhysicianUserId) return 1;
    return 0;
  };
}

/**
 * Gets the user ID of the current user's default physician, or undefined if there
 * is no default physician.
 *
 * "Default physician" is determined first by checking if the current user has
 * specified a default physician in settings. If not, then if the current user is
 * a physician, then they themselves are the default physician. Otherwise, if the
 * current user is staff and does not have a default physician configured, this
 * function will return null.
 */
export const getDefaultPhysicianUserId = createSelector(
  (state: RootState) => state.user,
  (currentUser): number | null => {
    if (currentUser.defaultPhysicianUserId) return currentUser.defaultPhysicianUserId;
    if (practiceUsersHelpers.isPhysician(currentUser)) return currentUser.id;
    return null;
  },
);

const getAllPracticeUsersById = (state: RootState): EntitiesById<PracticeUser> =>
  get(state.entities, ['practiceUsers', 'byId']);

/**
 * Returns an array of active practice users, sorted by full name.
 */
export const getPracticeUsers = createSelector(
  getAllPracticeUsersById,
  (practiceUsersById): PracticeUser[] | undefined => {
    if (!practiceUsersById) return undefined;

    return Object.values(practiceUsersById)
      .filter((user): boolean => user.isActive)
      .sort((a, b): number => a.fullName.localeCompare(b.fullName));
  },
);

export const getPracticeUsersInWorkspace = makeSelectWorkspaceFilteredEntites(
  getPracticeUsers,
  'PracticeUser',
);

export const getPracticeUser = (state: RootState, props: { userId: number }): PracticeUser =>
  get(state.entities, ['practiceUsers', 'byId', props.userId]);

/**
 * Returns a map of all practice users keyed by id.
 */
export const getAllPracticeUsersByIdSelector = createSelector(
  getAllPracticeUsersById,
  (practiceUsersById): EntitiesById<PracticeUser> | undefined => {
    if (!practiceUsersById) return undefined;

    return practiceUsersById;
  },
);

/**
 * Returns a map of active practice users keyed by id.
 */
export const getActivePracticeUsersById = createSelector(
  getAllPracticeUsersById,
  (practiceUsersById): EntitiesById<PracticeUser> | undefined => {
    if (!practiceUsersById) return undefined;

    const activeUsers = Object.values(practiceUsersById).filter((user) => user.isActive);

    return mapArrayToObject(activeUsers, 'id');
  },
);

/**
 * Returns an array of active physician users, sorted by full name. If the current
 * user has a default physician user set, that physician user will be the first
 * item in the array.
 *
 * May return undefined if practice users haven't been loaded yet.
 */
export const getPhysicianUsers = createSelector(
  getPracticeUsers,
  getDefaultPhysicianUserId,
  (practiceUsers, defaultPhysicianUserId): PracticeUser[] | undefined => {
    if (!practiceUsers) return undefined;

    const physicianUsers = practiceUsers.filter(practiceUsersHelpers.isPhysician);
    return defaultPhysicianUserId
      ? physicianUsers.sort(makeDefaultPhysicianSortFn(defaultPhysicianUserId))
      : physicianUsers;
  },
);

/**
 * Returns an array of physician users in the current workspace, sorted by full name.
 *
 * If the current user has a default physician user set and it is in the workspace,
 * that physician user will be the first item in the array.
 *
 * If the current user is a physician (regardless of presense in workspace), that user
 * object will be the first item in the array.
 *
 * If we are not in workspace will return the full active physician list
 *
 * May return undefined if practice users haven't been loaded yet.
 */
export const getPhysicianUsersInWorkspace = makeSelectWorkspaceFilteredEntites(
  getPhysicianUsers,
  'PracticeUser',
);

/**
 * Returns an object-map of physician users in the current workspace, keyed by `id`.
 */
export const getPhysicianUsersInWorkspaceById = createSelector(
  getPhysicianUsersInWorkspace,
  (physicianUsers: PracticeUser[] | undefined): EntitiesById<PracticeUser> | undefined => {
    if (!physicianUsers) return undefined;
    return mapArrayToObject(physicianUsers, 'id');
  },
);

/**
 * Returns an array of active staff users, sorted by full name.
 *
 */
export const getStaffUsers = createSelector(
  getPracticeUsers,
  (practiceUsers): PracticeUser[] | undefined => {
    if (!practiceUsers) return undefined;
    return practiceUsers.filter(practiceUsersHelpers.isStaff);
  },
);

/**
 * Returns an array of all physician users, sorted by full name.
 * If the current user has a default physician user set, that physician user will be the first
 * item in the array.
 */
export const getAllPhysicianUsers = createSelector(
  getAllPracticeUsersById,
  getDefaultPhysicianUserId,
  (practiceUsersById, defaultPhysicianUserId): PracticeUser[] | undefined => {
    if (!practiceUsersById) return undefined;

    const physicianUsers = Object.values(practiceUsersById)
      .filter((user) => practiceUsersHelpers.isPhysician(user))
      .sort((a, b): number => a.fullName.localeCompare(b.fullName));
    return defaultPhysicianUserId
      ? physicianUsers.sort(makeDefaultPhysicianSortFn(defaultPhysicianUserId))
      : physicianUsers;
  },
);

/**
 * Returns an array of inactive physician users.
 *
 */
export const getInactivePhysicianUsers = createSelector(
  getAllPhysicianUsers,
  (physicianUsers): PracticeUser[] | undefined => {
    if (!physicianUsers) return undefined;

    return Object.values(physicianUsers).filter((user) => !user.isActive);
  },
);

/**
 * Returns an array of all staff users, sorted by full name.
 *
 */
export const getAllStaffUsers = createSelector(
  getAllPracticeUsersById,
  (practiceUsersById): PracticeUser[] | undefined => {
    if (!practiceUsersById) return undefined;

    return Object.values(practiceUsersById)
      .filter((user) => practiceUsersHelpers.isStaff(user))
      .sort((a, b): number => a.fullName.localeCompare(b.fullName));
  },
);

/**
 * Returns an array of inactive staff users.
 *
 */
export const getInactiveStaffUsers = createSelector(
  getAllStaffUsers,
  (staffUsers): PracticeUser[] | undefined => {
    if (!staffUsers) return undefined;

    return staffUsers.filter((user) => !user.isActive);
  },
);

/**
 * Returns an object-map of active physician users, keyed by `id`.
 */
export const getPhysicianUsersById = createSelector(
  getPhysicianUsers,
  (physicianUsers): EntitiesById<PracticeUser> | undefined => {
    if (!physicianUsers) return undefined;

    return mapArrayToObject(physicianUsers, 'id');
  },
);

/**
 * Returns an object-map of active physician users, keyed by `staffId`.
 */
export const getPhysicianUsersByStaffId = createSelector(
  getPhysicianUsers,
  (
    physicianUsers,
  ):
    | {
        [staffId: number]: PracticeUser;
      }
    | undefined => {
    if (!physicianUsers) return undefined;

    return mapArrayToObject(physicianUsers, 'staffId');
  },
);

/**
 * Returns the list of active physician users who have made the current user an orders
 * delegate. If the current user has a default physician set, and that physician is
 * included in the returned results, it will be the first element in the resulting
 * array.
 */
export const getOrdersDelegatingPhysicians = createSelector(
  (state): PracticeUser => state.user,
  getPhysicianUsers,
  (currentUser, physicianUsers): PracticeUser[] | undefined => {
    if (!physicianUsers) return undefined;

    const physicianUsersByStaffId = mapArrayToObject(physicianUsers, 'staffId');

    return currentUser.delegatingPhysicianIdsMap.orders
      .filter((staffId): boolean => Boolean(physicianUsersByStaffId[staffId]))
      .map((staffId): PracticeUser => physicianUsersByStaffId[staffId])
      .sort(makeDefaultPhysicianSortFn(currentUser.defaultPhysicianUserId));
  },
);

/**
 * Identical to getOrdersDelegatingPhysicians but limits the following to the workspace:
 *
 * Returns the list of active physician users who have made the current user an orders
 * delegate. If the current user has a default physician set, and that physician is
 * included in the returned results, it will be the first element in the resulting
 * array.
 *
 * Workspace note: default physicians not in the workspace do not appear in the list
 */
export const getOrdersDelegatingPhysiciansInWorkspace = createSelector(
  getOrdersDelegatingPhysicians,
  getPhysicianUsersInWorkspace,
  (rxDelegates, workspacePhysicians): PracticeUser[] | undefined => {
    if (!rxDelegates || !workspacePhysicians) return undefined;

    return rxDelegates.filter((delegate) =>
      Boolean(workspacePhysicians.some((phys) => phys === delegate)),
    );
  },
);

/**
 * Returns the list of active physician users who have made the current user an
 * RX delegate. If the current user has a default physician set, and that physician
 * is included in the returned results, it will be the first element in the resulting
 * array.
 */
export const getRxDelegatingPhysicians = createSelector(
  (state): PracticeUser => state.user,
  getPhysicianUsers,
  (currentUser, physicianUsers): PracticeUser[] | undefined => {
    if (!physicianUsers) return undefined;

    const physicianUsersByStaffId = mapArrayToObject(physicianUsers, 'staffId');

    return currentUser.delegatingPhysicianIdsMap.rxs
      .filter((staffId): boolean => Boolean(physicianUsersByStaffId[staffId]))
      .map((staffId): PracticeUser => physicianUsersByStaffId[staffId])
      .sort(makeDefaultPhysicianSortFn(currentUser.defaultPhysicianUserId));
  },
);

/**
 * Identical to getRxDelegatingPhysicians but limits the following to the workspace:
 *
 * Returns the list of active physician users who have made the current user an
 * RX delegate. If the current user has a default physician set, and that physician
 * is included in the returned results, it will be the first element in the resulting
 * array.
 *
 * Workspace note: default physicians not in the workspace do not appear in the list
 */
export const getRxDelegatingPhysiciansInWorkspace = createSelector(
  getRxDelegatingPhysicians,
  getPhysicianUsersInWorkspace,
  (rxDelegates, workspacePhysicians): PracticeUser[] | undefined => {
    if (!rxDelegates || !workspacePhysicians) return undefined;

    return rxDelegates.filter((delegate) =>
      Boolean(workspacePhysicians.some((phys) => phys === delegate)),
    );
  },
);

/**
 * Returns true if the current user is an admin, or false if not.
 */
export const getIsUserAdmin = (state: RootState): boolean => state.user.isAdmin;

export const getCurrentUser = (state: RootState): PracticeUser => state.user;

/**
 * Returns a cache-busting timestamp for displaying the current profile photo,
 * since the URL for a user's photo does not change.
 */
export const getProfilePhotoLastUpdated = (state: RootState): number =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  state.entities.practiceUsers?.profilePhotoLastUpdated;

export const selectPracticeUserInviteStatus = (
  state: RootState,
  userId: number,
): UserInviteStatus | undefined => state.entities.practiceUsers?.inviteStatusByUserId?.[userId];
