import React from 'react';
import { useCurrentUser } from 'modules/practice-users';
import { useOpenInviteColleagueDialog, useOpenProviderSecurityCodeDialog } from 'ui-modules/navbar';
import { logEvent } from 'utils/amplitude';
import { ScopedSentryErrorBoundary } from 'utils/sentry/ScopedSentryErrorBoundary';
import { NavbarItem, NavbarItemDropdownProps } from '../../components/NavbarItem';
import { InviteColleagueDialog } from '../../dialogs/InviteColleagueDialog';
import { ProviderSecurityCodeDialog } from '../../dialogs/ProviderSecurityCodeDialog';
import styles from './AccountNavItem.less';

const getMenuItems = ({
  openInviteColleagueDialog,
  openProviderSecurityCodeDialog,
}: {
  openInviteColleagueDialog: VoidFunction;
  openProviderSecurityCodeDialog: VoidFunction;
}): NavbarItemDropdownProps['items'] => {
  const result: NavbarItemDropdownProps['items'] = [
    {
      'data-testid': 'navbar-account-settings-link',
      type: 'link',
      href: '/settings',
      text: 'Settings',
    },
    {
      'data-testid': 'navbar-account-product-updates-link',
      type: 'link',
      href: 'https://help.elationhealth.com/s/topic/0TO1G0000008q7sWAA/product-updates',
      text: 'Product Updates',
    },
  ];

  if (el8Globals.FEATURES.UserReferral && el8Globals.USER_STAFF_TYPE === 'Physician') {
    result.push({
      'data-testid': 'navbar-account-invite-colleague-button',
      type: 'button',
      text: 'Invite a Colleague',
      onClick: openInviteColleagueDialog,
    });
  }

  if (el8Globals.FEATURES.Letters) {
    result.push({
      id: 'provider-security-code',
      type: 'button',
      text: 'Provider Security Code',
      'data-testid': 'navbar-provider-security-code-button',
      onClick: openProviderSecurityCodeDialog,
    });
  }

  return result;
};

const AccountNavItemComponent = (): JSX.Element => {
  const currentUser = useCurrentUser();
  const openInviteColleagueDialog = useOpenInviteColleagueDialog();
  const openProviderSecurityCodeDialog = useOpenProviderSecurityCodeDialog();

  if (el8Globals.IN_APP_ONBOARDING) {
    return (
      <NavbarItem
        type="button"
        data-testid="navbar-account-button"
        rootClassname={styles.inAppOnboarding}
        tabIndex={-1}
      >
        {currentUser.email}
      </NavbarItem>
    );
  }

  return (
    <>
      <NavbarItem
        type="dropdown"
        items={getMenuItems({ openInviteColleagueDialog, openProviderSecurityCodeDialog })}
        data-testid="navbar-account-button"
        onClick={(): void => logEvent('open: navigation user menu')}
      >
        {currentUser.email}
      </NavbarItem>
      <InviteColleagueDialog />
      <ProviderSecurityCodeDialog />
    </>
  );
};

export const AccountNavItem = (): JSX.Element => {
  return (
    <ScopedSentryErrorBoundary
      withScope={(scope): void => {
        scope.setTag('fe_service', 'Navbar');
      }}
    >
      <AccountNavItemComponent />
    </ScopedSentryErrorBoundary>
  );
};
