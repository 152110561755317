import { useEffect, useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';

import usePrevious from './usePrevious';

type FormikFormResetOptions = {
  /**
   * A ref of an element to focus when the form transitions from inactive to inactive (for form dialogs, this
   * means when the dialog opens).)
   */
  focusElementRef?: React.MutableRefObject<HTMLElement>;

  /**
   * The form's data dependencies, i.e. data it needs to be able to initialize its values accurately. For example,
   * an Edit Appointment form's data dependency is the appointment object being edited. `value` should be the
   * dependency itself, while `key` should be a unique identifier for that dependency (usually its ID).
   *
   * Sample usage:
   * ```
   * initDependencies: [
   *   { key: patientId, value: patient },
   *   { key: patientPreferences?.id, value: patientPreferences }
   * ]
   * ```
   *
   *
   * This is our custom alternative to Formik's `enableReinitialize` config option. We want to avoid
   * `enableReinitialize` because it's too eager to reset the form for many of our use cases. In particular,
   * we don't want our forms to reset in the middle of multi-API-call submissions, nor reset when entities are
   * updated not as a result of the user's actions (e.g. websockets); both of these things happen when using
   * `enableReinitialize` because they will cause changes to the form's props.
   */
  initDependencies?: {
    key: number | string | boolean | null | undefined;
    value: unknown;
  }[];

  /**
   * Callback function called when the form transitions from active to inactive (for form dialogs, this
   * means when the dialog closes).
   */
  onCleanup?: () => void;
};

type InitDependencies = FormikFormResetOptions['initDependencies'];

/**
 * Returns true if all dependencies in the given dependency array have been loaded. In this context,
 * a dependency is considered loaded if its `value` is anything other than `undefined`. We consider
 * `null` values to be loaded because `null` is typically used to indicate an explicit absence of value
 * after loading has been completed (e.g. a patient not having any saved payment methods).
 *
 * If `dependencies` is falsy, this function returns true.
 */
const areDependenciesReady = (dependencies: InitDependencies): boolean => {
  if (!dependencies) return true;

  return dependencies.every((dep) => dep.value !== undefined);
};

/**
 * Returns true if the keys in `prevDependencies` and `dependencies` are not equal. This does NOT check
 * dependency values' equality because we only consider a dependency as changed if its **identity** has
 * changed (not just its fields).
 *
 * If either argument is falsy, this function returns false.
 */
const didDependenciesChange = (
  prevDependencies: InitDependencies,
  dependencies: InitDependencies,
): boolean => {
  if (!prevDependencies || !dependencies) return false;

  const prevKeys = prevDependencies.map((dep) => dep.key);
  const keys = dependencies.map((dep) => dep.key);
  return !isEqual(prevKeys, keys);
};

/**
 * A hook that handles resetting/initializing/reinitializing Formik forms.
 *
 * Note that this hook can only be used within a Formik context, e.g. a component
 * that's wrapped in the withFormik HOC. If you try using it in a component that
 * does not have a Formik context, errors will occur.
 */
export default function useFormikFormReset(
  isFormActive: boolean,
  options: FormikFormResetOptions = {},
): { isInitialized: boolean } {
  const { resetForm: baseResetForm } = useFormikContext();

  const { focusElementRef, initDependencies, onCleanup } = options;

  const [isInitialized, setIsInitialized] = useState(false);

  const prevIsFormActive = usePrevious(isFormActive);
  const prevInitDependencies = usePrevious(initDependencies);

  const resetForm = useCallback((): void => {
    // Formik has some issues with stale state... if we don't schedule the reset for next tick,
    // the form will be reset to outdated, potentially incorrect initialValues.
    setTimeout(baseResetForm, 0);
  }, [baseResetForm]);

  const cleanup = useCallback((): void => {
    resetForm();
    onCleanup?.();
    setIsInitialized(false);
  }, [onCleanup, resetForm]);

  const initialize = useCallback((): void => {
    if (isInitialized) return;

    setIsInitialized(true);
    resetForm();
  }, [isInitialized, resetForm]);

  useEffect(
    function cleanupOnInactive(): void {
      if (prevIsFormActive && !isFormActive) {
        cleanup();
      }
    },
    [cleanup, isFormActive, prevIsFormActive],
  );

  useEffect(
    function resetInitializedOnDependencyChange(): void {
      if (isFormActive && didDependenciesChange(prevInitDependencies, initDependencies)) {
        setIsInitialized(false);
      }
    },
    [initDependencies, isFormActive, prevInitDependencies],
  );

  useEffect(
    function initFormIfReady(): void {
      if (isFormActive && !isInitialized && areDependenciesReady(initDependencies)) {
        initialize();
      }
    },
    [initDependencies, initialize, isFormActive, isInitialized],
  );

  useEffect((): void => {
    if (focusElementRef?.current && !prevIsFormActive && isFormActive) {
      focusElementRef.current.focus();
    }
  }, [focusElementRef, isFormActive, prevIsFormActive]);

  return {
    isInitialized,
  };
}
