import {
  QueryKey,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { normalize } from 'normalizr';

import { VisitNoteTemplate } from 'EntityTypes';
import { AppQueryConfig, GetVisitNoteTemplatesResponseBody } from 'QueryTypes';
import { EntitiesById } from 'StoreTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';
import { makeUpdateFn } from 'utils/redux-query';

import { visitNoteTemplateSchema } from './visitNoteTemplatesSchemas';

type QueryConfigOptions = {
  ignoreWorkspace?: boolean;
};

const urls = {
  visitNoteTemplates(practiceId: number, options: QueryConfigOptions = {}): string {
    const { ignoreWorkspace } = options;
    const args = ignoreWorkspace ? `?ignore_workspace=${ignoreWorkspace}` : '';
    return `/practice/${practiceId}/visit-note-templates/${args}`;
  },
};

const queryKeys = {
  exportVisitNoteTemplate: (patientId: number, noteId: number, templateIds: number[]): QueryKey => [
    'patient',
    patientId,
    'visit-notes',
    noteId,
    'visit-note-templates',
    templateIds.join(';'),
  ],
  visitNoteTemplates: (practiceId: MaybeNullishId): QueryKey => [
    'practice',
    practiceId,
    'visit-note-templates',
  ],
};

/**
 * Fetch visit note templates for the specified practice.
 *
 * FUTURE: deprecate in favor of `useGetVisitNoteTemplates`. Main blocker
 * right now is that this is being used by a few class components, which
 * can't use hooks.
 */
// eslint-disable-next-line import/prefer-default-export
export const visitNoteTemplatesQuery = (
  practiceId: number,
  options: QueryConfigOptions = {},
): AppQueryConfig => ({
  url: urls.visitNoteTemplates(practiceId, options),
  transform: (
    responseJson: VisitNoteTemplate[],
  ): { visitNoteTemplates: EntitiesById<VisitNoteTemplate> } =>
    normalize(responseJson, [visitNoteTemplateSchema]).entities,
  update: {
    visitNoteTemplates: makeUpdateFn<VisitNoteTemplate>({ merge: false }),
  },
});

/**
 * Fetches and returns the list of `VisitNoteTemplates` for the specified
 * practice. If the current user is in a workspace, this list will be
 * narrowed to just the templates in that workspace.
 */
export function useGetVisitNoteTemplates(
  practiceId: MaybeNullishId,
  options?: UseQueryOptions<GetVisitNoteTemplatesResponseBody>,
): UseQueryResult<GetVisitNoteTemplatesResponseBody> {
  return useQuery({
    queryKey: queryKeys.visitNoteTemplates(practiceId),
    ...options,
    enabled: Boolean(practiceId) && options?.enabled !== false,
  });
}

/**
 * Returns a mutation for exporting a list of visit note templates to a visit
 * note.
 */
export function useExportVisitNoteTemplate() {
  return useMutation({
    mutationFn(variables: { patientId: number; noteId: number; templateIds: number[] }) {
      const { noteId, patientId, templateIds } = variables;
      const url = getQueryUrl(queryKeys.exportVisitNoteTemplate(patientId, noteId, templateIds));
      return jsonFetch(url, { method: 'POST' });
    },
  });
}
