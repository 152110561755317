import Feature from 'constants/Feature';
import { ExampleOfferView } from './trial-views/ExampleOfferView';
import { ExampleActivatedView } from './trial-views/ExampleActivatedView';
import { ExampleErrorView } from './trial-views/ExampleErrorView';
import { TrialViewProps } from './TrialActivationDialog';

export type TrialActivationViewConfig = {
  OfferView: React.FC<TrialViewProps>;
  ActivatedView: React.FC<TrialViewProps>;
  ErrorView: React.FC<TrialViewProps>;
};

export const trialActivationViews: { [key in Feature]?: TrialActivationViewConfig } = {
  [Feature.AIScribe]: {
    OfferView: ExampleOfferView,
    ActivatedView: ExampleActivatedView,
    ErrorView: ExampleErrorView,
  },
};
