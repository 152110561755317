import alert from 'utils/alert';
import getErrorMessage from './getErrorMessage';

/**
 * Shows an alert dialog for the given error.
 * Accepts `unknown` for first argument to streamline usage with catch blocks,
 * which have their errors typed as `unknown` for newer versions of Typescript
 */
export default function alertError(error: Error | unknown, title?: string): void {
  if (!(error instanceof Error)) return;
  alert(getErrorMessage(error), title);
}
