import moment from 'moment';
import { DATE_FORMATS } from 'constants/index';

/**
 * helper function that takes two dates and returns humanize difference or the
 * first date if the difference is more than one day.
 * @param {Date} startDate — start of the interval
 * @param {Date} endDate — end of the interval
 * @param {String} dateFormat — string with a date format for moment format()
 * see https://momentjs.com/docs/#/displaying/
 * @returns {String} a human
 *
 */

export const formatDateDiff = (
  startDate,
  endDate = Date.now(),
  dateFormat = DATE_FORMATS.MEDIUM_DATE,
) => {
  const mDate = moment(startDate);
  const diff = moment.duration(mDate.diff(endDate));
  if (Math.abs(diff.days()) > 0) {
    return mDate.format(dateFormat);
  }
  return diff.humanize(true);
};
