import 'utils/polyfill';
import React from 'react';
import { ScopedSentryErrorBoundary } from 'utils/sentry/ScopedSentryErrorBoundary';
import styles from './Navbar.less';
import { ElationLogoCircle } from './components/ElationLogoCircle';
import { NavbarItem } from './components/NavbarItem';
import { ReportsNavItem } from './items/ReportsNavItem';
import { INeedHelpNavItem } from './items/INeedHelpNavItem';
import { AccountNavItem } from './items/AccountNavItem';
import { WorkspaceContextSwitcherItem } from './items/WorkspaceContextSwitcherItem';
import { PracticeContextSwitcherItem } from './items/PracticeContextSwitcherItem';
import { FindChartItem } from './items/FindChartItem';
import { LogoutItem } from './items/LogoutItem';
import { EmergencyOnlyStatus } from './components/EmergencyOnlyStatus';
import { ElationLogoFull } from './components/ElationLogoFull';
import { AIScribeTrialNavItem } from './items/AIScribeTrialNavItem/AIScribeNavItem';

const NavbarContainer = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <>
      <nav className={styles.base} aria-label="main">
        <ul className={styles.ul}>{children}</ul>
      </nav>
      {el8Globals.IS_EMERGENCY_MODE && <EmergencyOnlyStatus />}
    </>
  );
};

const NavbarLeftSection = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <ul className={styles.ulSection}>
      {el8Globals.LOGOUT_ONLY_NAV ? <ElationLogoFull /> : <ElationLogoCircle />}
      {children}
    </ul>
  );
};

const NavbarRightSection = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  return <ul className={styles.ulSection}>{children}</ul>;
};

/**
 * The global app navbar.
 */
const BaseNavbar = (): JSX.Element | null => {
  const isPatient = el8Globals.USER_STAFF_TYPE === 'Patient';
  const isSandbox = el8Globals.SANDBOX;
  const isTease = el8Globals.TEASE;
  if (el8Globals.NO_NAV) return null;
  if (el8Globals.IN_APP_ONBOARDING) {
    return (
      <NavbarContainer>
        <NavbarLeftSection>
          <INeedHelpNavItem />
        </NavbarLeftSection>
        <NavbarRightSection>
          <AccountNavItem />
          <LogoutItem />
        </NavbarRightSection>
      </NavbarContainer>
    );
  }

  return (
    <NavbarContainer>
      <NavbarLeftSection>
        {!el8Globals.LOGOUT_ONLY_NAV ? (
          <>
            <NavbarItem
              type="link"
              href="/patients"
              data-testid="navbar-practice-home-link"
              rootId="queuenav"
              target="_self"
            >
              Practice Home
            </NavbarItem>
            {!isPatient && !isSandbox && !isTease && (
              <>
                <FindChartItem />
              </>
            )}
            <ReportsNavItem />
            {(el8Globals.FEATURES.ElationBilling || el8Globals.FEATURES.ElationBillingNav) && (
              <NavbarItem
                type="link"
                href={el8Globals.ELATION_BILLING_ORIGIN}
                data-testid="navbar-billing"
              >
                Elation Billing
              </NavbarItem>
            )}
            {!isSandbox && !isTease && <INeedHelpNavItem />}
            {el8Globals.SHOW_NEW_USER_INTRO && (
              <NavbarItem type="link" href="/patient/demo/" data-testid="navbar-sample-chart">
                Sample Chart
              </NavbarItem>
            )}
            {el8Globals.IS_LAB_VENDOR && el8Globals.HL7_VALIDATION_INTEGRATOR_TOOL_URL && (
              <NavbarItem
                type="link"
                href={el8Globals.HL7_VALIDATION_INTEGRATOR_TOOL_URL}
                data-testid="navbar-sample-chart"
              >
                HL7 Testing Tool
              </NavbarItem>
            )}
            {el8Globals.FEATURES.InAppPurchElationAI && !el8Globals.FEATURES.AIScribe && (
              <AIScribeTrialNavItem />
            )}
          </>
        ) : null}
      </NavbarLeftSection>
      <NavbarRightSection>
        {isSandbox || isTease ? (
          <NavbarItem type="button" data-testid="navbar-sandbox-visitor-name">
            Hello, <span id="sandbox-visitor-name">Doctor!</span> Welcome to Elation.
          </NavbarItem>
        ) : (
          <>
            {el8Globals.FEATURES.Workspaces ? <WorkspaceContextSwitcherItem /> : null}

            {el8Globals.FEATURES.ContextSwitcher ? <PracticeContextSwitcherItem /> : null}

            {!el8Globals.LOGOUT_ONLY_NAV ? <AccountNavItem /> : null}

            <LogoutItem />
          </>
        )}
      </NavbarRightSection>
    </NavbarContainer>
  );
};

export const Navbar = (): JSX.Element => {
  const errorMessage =
    'An error occured trying to display the navigation bar. Please contact Elation support if the issue persists.';

  return (
    <ScopedSentryErrorBoundary
      errorMessage={errorMessage}
      withScope={(scope): void => {
        scope.setTag('fe_service', 'Navbar');
      }}
    >
      <BaseNavbar />
    </ScopedSentryErrorBoundary>
  );
};
