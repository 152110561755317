import { AnyWebsocketMessage } from 'utils/websockets';
import { AnyMessengerMessage } from './messengerTypes';

export enum MessengerEvent {
  /**
   * Signals that a medical diagnosis (ICD10) should be be added to the current Bill 2.0 form.
   */
  BillingAddDiagnosis = 'BillingAddDiagnosis',

  /**
   * Signals that a medical procedure (CPT) should be be added to the current Bill 2.0 form.
   */
  BillingAddProcedure = 'BillingAddProcedure',

  /**
   * Signals that a medical diagnosis (ICD10) should be be removed from the current Bill 2.0 form.
   */
  BillingRemoveDiagnosis = 'BillingRemoveDiagnosis',

  /**
   * Signals that a clinical form collection has been updated locally.
   */
  ClinicalFormCollectionUpdate = 'ClinicalFormCollectionUpdate',

  /**
   * Signals that a lab result should be exported to a note (specifically VN2).
   */
  ExportLabResultToNote = 'ExportLabResultToNote',

  /**
   * Signals that a report should be exported to a note (specifically VN2).
   */
  ExportReportToNote = 'ExportReportToNote',

  /**
   * Signals that the Nabla Copilot extension is exporting a transcription summary into the EHR.
   */
  NablaCopilotExportSummary = 'NablaCopilotExportSummary',

  /**
   * Signals that an order (MedOrder, LabOrder, ReferralOrder) was created or updated.
   */
  OrderUpdate = 'OrderUpdate',

  /**
   * Signals that a problem (PatientProblem) was created or updated.
   */
  ProblemUpdate = 'ProblemUpdate',

  /**
   * Signals that the patient profile in the patient chart page should be refreshed.
   */
  PatientProfileRefresh = 'PatientProfileRefresh',

  /**
   * Signals that a report was viewed in the report feed - specifically, that the report
   * was expanded.
   */
  ReportViewed = 'ReportViewed',

  /**
   * Signals that the billing data has updated and the legacy visit note bill summary or chart feed should be refreshed.
   */
  VisitNoteBillingDataRefresh = 'VisitNoteBillingDataRefresh',

  /**
   * Signals that a user invoked a slash command event to signal VisitNote2
   */
  VisitNote2SlashCommandEvent = 'VisitNote2SlashCommandEvent',

  /**
   * Signals that a websocket message was received.
   */
  WebsocketEvent = 'WebsocketEvent',
}

const DEFAULT_GLOBAL_CHANNEL = '__default-global';

type ListenersMap = {
  [messengerEvent in MessengerEvent]?: {
    // FUTURE: RM-33701 - Refactor ListenersMap to support any callback typings w/o explicit `any`
    /** Explicit `any` used to allow typing callback to either AnyWebsocketMessage | AnyMessengerMessage */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [channelName: string]: MessengerListenerCallback<any>[];
  };
};

type MessengerListenerCallback<
  TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage,
> = (payload?: TMessage) => void | false;

let listenersMap: ListenersMap = Object.create(null);

const getListenersForChannel = <
  TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage,
>(
  event: MessengerEvent,
  channel: string,
): MessengerListenerCallback<TMessage>[] | undefined => {
  return listenersMap[event]?.[channel];
};

const invokeChannelListeners = <
  TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage,
>(
  listeners: MessengerListenerCallback<TMessage>[],
  payload?: TMessage,
): void => {
  for (let i = 0; i < listeners.length; i++) {
    const listener = listeners[i];
    try {
      // Listeners may return `false` to stop further processing of that event on the same channel
      if (listener(payload) === false) break;
    } catch (err) {
      console.error(err);
    }
  }
};

const messenger = {
  /**
   * Adds a listener/callback for the specified event, and optionally, the specified channel.
   *
   * By default, events are sent to all channels, and all registered listeners across all channels
   * will have the chance to respond to the event. There are two scenarios in which this will not
   * be the case:
   *
   * 1. The event was explicitly sent to a specific channel only, in which case only that channel's
   *    listeners will see the event, or
   * 2. A listener returns `false` when processing an event. This stops further propagation of the
   *    event within that listener's channel, and any subsequent listeners also in that channel
   *    will not see the event. This should generally be used to signal that within the context of
   *    that channel, the event has been sufficiently processed and needs no further processing.
   *    This can be used to, for example, dedupe data fetching when multiple components have
   *    registered listeners for data updates that need to re-fetch the updated data.
   *
   * @param event - the event to listen for
   * @param callback - a function to call whenever an instance of the specified event is sent.
   * @param [channel] - (optional) the channel to register the callback on. Defaults to `DEFAULT_GLOBAL_CHANNEL`.
   * @returns the callback, for convenience, since a handle to the
   * callback is necessary to un-register it later.
   */
  on<TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage>(
    event: MessengerEvent,
    callback: MessengerListenerCallback<TMessage>,
    channel = DEFAULT_GLOBAL_CHANNEL,
  ): (payload: TMessage) => void {
    if (!listenersMap[event]) {
      listenersMap[event] = {};
    }

    const listenersForEvent = listenersMap[event];
    // this should always be true because we just ensured it exists with the
    // `if` block above, but it's here to make Typescript happy (will error
    // otherwise unless we use non-null assertions, which linter forbids)
    if (listenersForEvent) {
      if (!listenersForEvent[channel]) {
        listenersForEvent[channel] = [];
      }
      listenersForEvent[channel].push(callback);
    }

    return callback;
  },

  /**
   * Removes the given callback from the listeners array for the given event/channel,
   * if present. If there are no listeners for the given event/channel or the given
   * callback doesn't exist in the event/channel's listeners array, this function is
   * a no-op.
   *
   * @param event - the event to modify the listeners array for
   * @param callback - the callback to remove. The handle to this callback
   * is usually obtained from the return value of `on`.
   * @param [channel] - (optional) the channel to remove the callback from. Defaults to `DEFAULT_GLOBAL_CHANNEL`.
   * @returns true if the callback was found (and removed), or false if
   * it wasn't registered for the given event/channel.
   */
  off<TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage>(
    event: MessengerEvent,
    callback: MessengerListenerCallback<TMessage>,
    channel = DEFAULT_GLOBAL_CHANNEL,
  ): boolean {
    const listenersForChannel = getListenersForChannel<TMessage>(event, channel);
    if (listenersForChannel) {
      const index = listenersForChannel.indexOf(callback);
      if (index !== -1) {
        listenersForChannel.splice(index, 1);
        return true;
      }
    }

    return false;
  },

  /**
   * Sends an event and payload to any and all listeners listening for the particular
   * event. The listeners will be executed in the order they were registered in.
   *
   * Optionally, this function can be used to send an event to a specific channel by
   * specifying the `channel` parameter.
   *
   * @param event - the event to send
   * @param payload - an object that may contain any properties, and
   * will be provided to listeners as the sole function argument.
   * @param [channel] - (optional) a specific channel to send the message to. If not specified,
   * the message will be sent to all channels.
   */
  send<TMessage extends AnyWebsocketMessage | AnyMessengerMessage = AnyWebsocketMessage>(
    event: MessengerEvent,
    payload?: TMessage,
    channel?: string,
  ): void {
    // send to specific channel
    if (channel) {
      const listenersForChannel = getListenersForChannel<TMessage>(event, channel);
      if (listenersForChannel) {
        invokeChannelListeners<TMessage>(listenersForChannel, payload);
      }
    } else {
      const listenersForEvent = listenersMap[event];
      if (listenersForEvent) {
        // send to all channels
        Object.values(listenersForEvent).forEach((listenersForChannel) => {
          invokeChannelListeners(listenersForChannel, payload);
        });
      }
    }
  },

  // A function only used for tests for now
  reset(): void {
    listenersMap = Object.create(null);
  },
};

export default messenger;
