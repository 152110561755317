import toast from 'utils/notify/toast';

const defaultErrorMessage =
  'There was a problem with your request. Please try again. If the problem persists, reload your page.';

export const maybeHandleRequestErrors = (
  response,
  customHandlerOrErrorMessage = defaultErrorMessage,
) => {
  if (!response || response.status !== 200) {
    if (typeof customHandlerOrErrorMessage === 'function') {
      customHandlerOrErrorMessage(response);
    } else {
      toast(customHandlerOrErrorMessage);
    }
    return Promise.reject(response);
  }
  return response;
};
