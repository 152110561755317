import React, { useRef } from 'react';

import { NextStep, Persona, QuestionCategories } from '@elation/api-client/src/support';
import * as yup from 'yup';

import { Formik } from 'formik';

import { CaseIntakeStep } from 'modules/support/supportConstants';

import { useCurrentUser } from 'modules/practice-users';
import StaffType from 'constants/StaffType';
import { PracticeUser } from 'EntityTypes';

import { isValidFileType, SFDC_MAX_FILE_SIZE } from './supportedFileTypes';
import { StepConfig } from './CaseIntakeStepConfig';
import { JsonFormsProvider } from './hooks/jsonFormsContext';

const BYTES_TO_MB = 1.0 / (1024 * 1024);

export type CaseIntakeFormValues = {
  question: string;
  persona?: Persona;
  is_urgent: boolean;
  details: string;
  supportCaseFile?: File;
  error_messages?: string;
  reproduction_steps?: string;
  name: string;
  email: string;
  answer?: string;
  directed_to_step?: NextStep;
  category?: QuestionCategories;
  integrationVendor?: string;
};

const isValidPersona = (personaName: string): personaName is Persona =>
  Object.keys(Persona).includes(personaName);

export type CaseIntakeStepProps = {
  goToNextStep: (nextStep: CaseIntakeStep) => void;
  goToPrevStep: () => void;
  formRootRef: React.RefObject<HTMLDivElement>;
};

const getInitialValues = (user: PracticeUser): CaseIntakeFormValues => {
  let persona: Persona | undefined;
  if (user && (user.staffType === StaffType.Physician || user.staffType === StaffType.Staff)) {
    persona = Persona.ehr_user;
  } else {
    persona = isValidPersona(elate.support.persona) ? Persona[elate.support.persona] : undefined;
  }

  return {
    question: '',
    persona,
    is_urgent: false,
    details: '',
    name: user?.staffType ? `${user.firstName} ${user.lastName}` : '',
    email: user?.email || '',
    error_messages: '',
    reproduction_steps: '',
    category: QuestionCategories.something_else,
    integrationVendor: undefined,
  };
};

const supportIntakeFormSchema = yup.object({
  persona: yup.mixed().oneOf(Object.values(Persona)).required('Role selection required'),
  details: yup.string().trim().required('Fill out the details of your issue or question'),
  category: yup
    .mixed()
    .oneOf(Object.values(QuestionCategories))
    .when('persona', {
      is: (persona) => persona === Persona.ehr_user || persona === Persona.vendor,
      then: yup
        .mixed()
        .oneOf(Object.values(QuestionCategories))
        .required('Issue selection required'),
    }),
  integrationVendor: yup.string().when('category', {
    is: (category) => category === QuestionCategories.integration_request,
    then: yup.string().required('Integration vendor required'),
  }),
});

const supportCaseFormSchema = yup.object({
  question: yup.string().required('Fill out the details of your issue or question'),
  error_messages: yup.string(),
  reproduction_steps: yup.string(),
  supportCaseFile: yup
    .mixed()
    .test('is-valid-type', 'Select a valid file type', (file: File) =>
      file ? isValidFileType(file.name) : true,
    )
    .test(
      'is-valid-size',
      (file) =>
        `Max file size is 10 MB, selected file is ${(file.value.size * BYTES_TO_MB).toFixed(2)} MB`,
      (file: File) => (file ? file.size <= SFDC_MAX_FILE_SIZE : true),
    ),
});

const supportCaseFormContactSchema = yup.object({
  name: yup.string().required('Enter your name'),
  email: yup.string().email('Enter a valid email').trim().required('Enter a valid email').max(75),
});

const getValidationSchema = (
  currentStep: CaseIntakeStep,
): yup.ObjectSchema<Partial<CaseIntakeFormValues>> => {
  switch (currentStep) {
    case CaseIntakeStep.Intake:
      return supportIntakeFormSchema;
    case CaseIntakeStep.create_case:
      return supportCaseFormSchema;
    case CaseIntakeStep.ContactInfo:
      return supportCaseFormContactSchema;
    default:
      return yup.object({});
  }
};

type Props = {
  steps: CaseIntakeStep[];
  setSteps: React.Dispatch<React.SetStateAction<CaseIntakeStep[]>>;
  isInModal: boolean;
};

const SupportForm: React.FC<Props> = ({ steps, setSteps, isInModal }): JSX.Element => {
  const currentStep = steps[steps.length - 1];
  const FormStep = StepConfig[currentStep].component;
  const formRootRef = useRef<HTMLDivElement>(null);
  const user = useCurrentUser();

  const handleNext = (nextStep: CaseIntakeStep): void => {
    setSteps((CaseIntakeSteps) => [...CaseIntakeSteps, nextStep]);
  };

  const handlePrev = (): void => {
    if (steps.length <= 1) return;
    setSteps(() => steps.slice(0, steps.length - 1));
  };

  return (
    <JsonFormsProvider>
      <Formik
        initialValues={getInitialValues(user)}
        onSubmit={(): void => {}}
        validationSchema={getValidationSchema(currentStep)}
      >
        <div ref={formRootRef} data-dd-privacy="mask-user-input">
          <FormStep
            formRootRef={formRootRef}
            goToNextStep={handleNext}
            goToPrevStep={handlePrev}
            isInModal={isInModal}
          />
        </div>
      </Formik>
    </JsonFormsProvider>
  );
};

export default SupportForm;
