import React from 'react';
import { Button2, DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { TrialViewProps } from '../TrialActivationDialog';

export const ExampleErrorView: React.FC<TrialViewProps> = ({ onClose }) => {
  return (
    <Stack>
      <Flexbox justify="space-between" align="center">
        <HeaderText tag="h1">Something Went Wrong</HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <Text2>
        Arcu non sodales neque sodales. Habitant morbi tristique senectus et netus et. Quis risus
        sed vulputate odio. Volutpat consequat mauris nunc congue nisi vitae. At auctor urna nunc id
        cursus metus aliquam eleifend. Felis donec et odio pellentesque. Mus mauris vitae ultricies
        leo integer. Massa enim nec dui nunc mattis enim ut tellus elementum. Amet mattis vulputate
        enim nulla aliquet porttitor. Sit amet cursus sit amet dictum. Vitae tortor condimentum
        lacinia quis vel eros. Euismod nisi porta lorem mollis aliquam. Diam volutpat commodo sed
        egestas egestas fringilla phasellus faucibus scelerisque.
      </Text2>
      <Button2 label="Close" onClick={onClose} />
    </Stack>
  );
};
