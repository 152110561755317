import React from 'react';
import { logOpenTrialDialog } from 'views/shared/trial-activation/amplitudeHelpers';
import {
  useCloseTrialActivationDialog,
  useOpenTrialActivationDialog,
} from 'ui-modules/trial-activation';
import { useAppSelector } from 'utils/hooks';
import TrialActivationDialog from 'views/shared/trial-activation/TrialActivationDialog';
import Feature from 'constants/Feature';
import { NavbarItem } from '../../components/NavbarItem';

export const AIScribeTrialNavItem = (): JSX.Element => {
  const openTrialActivationDialog = useOpenTrialActivationDialog();
  const closeTrialActivationDialog = useCloseTrialActivationDialog();
  const trialState = useAppSelector((state) => state?.trialActivation?.trialActivation);
  return (
    <>
      <NavbarItem
        type="button"
        onClick={() => {
          logOpenTrialDialog('AIScribe', 'nav-bar');
          openTrialActivationDialog({
            featureName: Feature.AIScribe,
          });
        }}
        data-testid="navbar-sample-chart"
      >
        Start Trial
      </NavbarItem>
      {trialState?.isOpen && (
        <TrialActivationDialog {...trialState} onClose={closeTrialActivationDialog} />
      )}
    </>
  );
};
