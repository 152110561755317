/**
 * A helper function that simplifies pushing to arrays in maps of arrays,
 * because it's not always known whether or not an array at a particular key in
 * the map already exists or not.
 *
 * This function will only create-and-push if the value at the specified key is
 * undefined. Any other value besides an array, including null, will result in a
 * no-op.
 *
 * @param {object} map - the map to use. The map will be mutated.
 * @param {any} key - the key for the array that should be pushed into
 * @param {any} value - the value to push into the array
 */
export function safeArrayMapPush(map, key, value) {
  const currentValue = map[key];
  if (currentValue === undefined) {
    // eslint-disable-next-line no-param-reassign
    map[key] = [value];
  } else if (Array.isArray(currentValue)) {
    currentValue.push(value);
  } else {
    console.warn(
      `\`safeArrayMapPush\` found a non-undefined, non-array value at key ${key}. This is a no-op.`,
    );
  }
}
